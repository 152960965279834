import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#333',
        // secondary: '#ffb200',
        secondary: '#F58D33',
        // blueDark: '#1584d1' #325AAF
        greyText: '#707070',
        orange: '#F58D33',
        blueDark: '#325AAF',
        blueLight: '#4D608D'
      }
    }
  }
})
