<template>
  <div>
    <v-footer
    padless
    elevation="3"
    >
      <v-card
      tile
      width="100%"
      color="blueDark"
      >
        <v-card-text class="footer">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="3">
                <v-img src="../static/logo.png" width="100"></v-img>
                <p class="body-2 mt-5 pr-5 white--text">
                  Fare 33 was established by experts with over 35 years of experience in multiple facets of the travel industry. Our team has been involved in the evolution of the technological advancement of ticketing from the very beginning. With that knowledge and years of experience with the airlines, we are uniquely situated to provide you with the best prices and the smoothest user experience in the market.
                </p>
                <div>
                  <strong class="white--text">Follow us: </strong>
                  <a v-for="item in social" class="mx-3 social-links" :key="item.icon" :href="item.link" target="_blank">
                    <v-icon color="white">{{item.icon}}</v-icon>
                  </a>
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Easy Access</span>
                <hr class="divider">
                <ul class="links-list">
                  <li>
                    <router-link to="/about">About</router-link>
                  </li>
                  <li>
                    <router-link to="/flights">Flights</router-link>
                  </li>
                  <li>
                    <router-link to="/hotels">Hotels</router-link>
                  </li>
                  <li>
                    <router-link to="/cars">Cars</router-link>
                  </li>
                  <li>
                    <router-link to="/help">Customer Service</router-link>
                  </li>
                </ul>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Legal & Rewards</span>
                <hr class="divider">
                <ul class="links-list">
                  <li>
                    <router-link to="/terms">Terms & conditions</router-link>
                  </li>
                  <li>
                    <router-link to="/privacy">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/rewards">Rewards</router-link>
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <span class="white--text font-weight-bold" style="font-size: 20px;">Contacts</span>
                <hr class="divider">
                <div class="my-5">
                  <v-icon color="white">mdi-email-open</v-icon>
                  <span class="ml-3 white--text caption">info@Fare33.com</span>
                </div>
                <div class="my-5">
                  <v-icon color="white">mdi-map-marker</v-icon>
                  <span class="ml-3 white--text caption">770 N Lasalle Ave, Suite 405, Chicago IL 60654</span>
                </div>
                <div class="align-center">
                  <img src="../static/icons/payment-icons.png" height="40px" class="mr-5">
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <hr style="background-color: grey; border: none; height: 0.5px;">
        <!-- <v-row justify="space-between" class="pa-5" no-gutters> -->
          <!-- <div>
            <a v-for="item in social" class="mr-5 social-links" :key="item.icon" :href="item.link" target="_blank"><v-icon :color="item.color">{{item.icon}}</v-icon></a>
          </div> -->
          <p class="text-center bod-2 white secondary--text text-right mb-0 py-3">
            &copy; {{ new Date().getFullYear() }} All rights reserved. Fare33
          </p>
        <!-- </v-row> -->
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      dialog1: false,
      title: '',
      social: [
        { icon: 'mdi-facebook', link: 'https://www.facebook.com/Fare33TravelServices', color: '#1877f2' },
        { icon: 'mdi-twitter', link: 'https://twitter.com/fare33_info', color: '#1da1f2' },
        { icon: 'mdi-instagram', link: 'https://www.instagram.com/fare33.info/', color: '#c32aa3' }
      ]
    }
  },
  methods: {
    goTo (page) {
      this.$router.push({ name: page })
    }
  }
}
</script>

<style scoped>
  .footer {
    background-image: url('../static/map.png');
    background-size: contain;
    background-position: center;
  }
  .download-link {
    text-decoration: none;
    font-weight: 700;
    font-size: 1rem;
  }
  .details {
    font-size: 11px;
    margin-right: 15px;
  }
  h3 {
    font-size: 15px;
  }
  ul {
    list-style: none;
  }
  .social-links {
    text-decoration: none;
  }
  .list-items {
    text-decoration: none;
    color: grey;
    font-size: 12px;
    transition: .2s linear;
  }
  .list-items:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .subscripe {
    min-height: 220px;
    position: relative;
  }
  .btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .divider {
    background-color:#fff;
    width: 50px;
    height: 3px;
    margin-top: 5px;
    border: none;
  }
  .privacy-link {
    color: grey;
    text-decoration: none;
  }
  .privacy-link:hover {
    cursor: pointer;
    color: white;
  }
  .adam-footer {
    border-top: 3px solid #FDB71A !important;
  }
  .social-adam-cont {
    display: flex;
    margin-top: 5px;
  }
  .social-adam {
    background-color: #FDB71A;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    text-align: center;
  }
  .social-adam:hover {
    background-color: #f1ad1a;
  }
  .social-adam span {
    color: #1a3057;
    font-size: 1.3rem;
    font-weight: 900;
  }
  .social-adam-cont a {
    text-decoration: none;
  }
  @media screen and (max-width: 960px) {
    .brand {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .details {
      margin-top: 50px;
      text-align: center;
    }
    ul {
      text-align: center;
      margin: 20px;
    }
  }
  @media screen and (max-width: 526px) {
    ul {
     list-style: disc;
    }
  }
  .links-list {
    list-style: none;
    margin-top: 20px;
    padding-left: 0px;
  }
  .links-list li {
    margin: 10px 0;
    text-align: left;
  }
  .links-list li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
  }
</style>
