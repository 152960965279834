<template>
  <div v-if="promotion">
    <v-card class="rounded-xl" outlined color="#f7f7f7" elevation="0">
      <v-img
        height="200"
        lazy-src="https://picsum.photos/id/11/100/60"
        :src="
          promotion.destinationImg
            ? promotion.destinationImg
            : require('../static/flight.jpg')
        "
        gradient="to top, rgba(100,115,201,0), rgba(25,32,72,0.3)"
        class="pa-3"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <div class="d-flex justify-space-between flex-wrap">
          <div class="glass-bg rounded-pill pa-3 white--text body-1 ma-1">
            <div v-if="promotion.amountType === 'percentage'">
              <v-icon color="yellow darken-1" left>mdi-sale</v-icon>
              {{ "Save " + promotion.discountAmount + "%" }}
            </div>
            <div v-else>
              <v-icon color="yellow darken-1" left>mdi-cash</v-icon>
              From {{ promotion.discountAmount | changeCurrency }}
            </div>
          </div>

          <div
            class="glass-bg rounded-pill pa-3 white--text body-1 ma-1 font-weight-bold"
          >
            <v-icon color="white" left>{{
              promotion.flightDirection === "round"
                ? "mdi-swap-horizontal"
                : "mdi-arrow-right-thin"
            }}</v-icon>
            {{ promotion.flightDirection === "round" ? "Round Trip" : "One way" }}
          </div>
        </div>
      </v-img>

      <div class="white rounded-b-xl">
        <v-card-title class="font-weight-bold body-1"
          >{{ promotion.origin.City + ", " + promotion.origin.Code }}
          <v-icon color="black" class="mx-2">{{
            promotion.flightDirection === "round"
              ? "mdi-swap-horizontal"
              : "mdi-arrow-right-thin"
          }}</v-icon>
          {{
            promotion.destination.City + ", " + promotion.destination.Code
          }}</v-card-title
        >

        <v-card-subtitle class="body-2 mt-1"
          >Travel from {{ promotion.origin.City + ", " + promotion.origin.Code }} to
          {{ promotion.destination.City + ", " + promotion.destination.Code }}
          between
          <strong>{{
            new Date(promotion.departureDate.replaceAll("-", "/")).toDateString()
          }}</strong>
          and
          <strong>{{
            new Date(promotion.returnDate.replaceAll("-", "/")).toDateString()
          }}</strong>
          <Strong v-if="promotion.amountType === 'percentage'">
            and Save until {{ promotion.discountAmount }}%</Strong
          >
          <Strong v-else>
            Starting from {{ promotion.discountAmount | changeCurrency }}</Strong
          >

          <!-- <div class="mt-3">
                <picker key="return" v-if="promotion.flightDirection === 'round'" type="Promotion Return Date" :start="promotion.departureDate" :end="promotion.returnDate"></picker>
                <picker key="depart1" v-else type="Promotion Departure Date" :start="promotion.departureDate" :end="promotion.returnDate"></picker>
            </div> -->
        </v-card-subtitle>
      </div>

      <div class="mt-2 px-2 pt-1">
        <v-row justify="space-between" no-gutters align="center" class="pb-1 mt-2">
          <v-col cols="12" md="6" class="mb-2">
            <v-select
              v-model="chosenClass"
              :items="promotion.cabinClasses"
              label="Select Class"
              solo
              dense
              hide-details
              rounded
              flat
            >
              <template v-slot:item="{ item }">
                <span>{{ classes.find((el) => el.value === item).text }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span>{{ classes.find((el) => el.value === item).text }}</span>
              </template>
            </v-select>
            <!-- <v-chip class="caption ma-1" v-for="cabinClass in promotion.cabinClasses" :key="cabinClass">
                {{ classes.find(el => el.value === cabinClass).text }}
                </v-chip> -->
          </v-col>
          <v-col cols="12" md="5" class="mb-2">
            <v-btn block rounded color="info" dark elevation="0" @click="searchPromotion"
              >Book Now</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
// import picker from './datePicker.vue'
export default {
  // components: {
  //   picker,
  // },
  props: ['promotion'],
  data () {
    return {
      chosenClass: '',
      classes: [
        { text: 'Economy', value: 'Y' },
        { text: 'Premium Economy', value: 'S' },
        { text: 'Business', value: 'C' },
        { text: 'First', value: 'F' }
      ]
    }
  },
  methods: {
    searchPromotion () {
      this.$store.dispatch('setAirports', {
        data: this.promotion.origin,
        type: 'departure1'
      })
      this.$store.dispatch('setAirports', {
        data: this.promotion.destination,
        type: 'arrival1'
      })
      this.$store.dispatch('setType', this.promotion.flightDirection)
      this.$store.dispatch('setPassengersAdults', Number(1))
      this.$store.dispatch('setPassengersChildren', Number(0))
      this.$store.dispatch('setPassengersInfants', Number(0))
      this.$store.dispatch(
        'setPassengersClass',
        this.classes.find((el) => el.value === this.chosenClass)
      )

      const searchStore = this.$store.state
      let data = ''
      if (new Date(this.promotion.departureDate).getTime() > new Date().getTime()) {
        this.$store.dispatch('setDates', {
          data: this.promotion.departureDate,
          type: 'departure1'
        })
      } else {
        const tommorow = new Date().setDate(new Date().getDate() + 1)
        const date = new Date(tommorow).toISOString().substr(0, 10)
        this.$store.dispatch('setDates', { data: date, type: 'departure1' })
      }

      const searchParameters = {
        tripType: this.promotion.flightDirection,
        origin: searchStore.airports.departureAirports[0].Code,
        destination: searchStore.airports.arrivalAirports[0].Code,
        originType: searchStore.airports.departureAirports[0].Type,
        destinationType: searchStore.airports.arrivalAirports[0].Type,
        departureDate: searchStore.flightDates.departureDates[0],
        classType: searchStore.passengers.classType.value,
        adults: searchStore.passengers.adults,
        children: searchStore.passengers.children,
        infants: searchStore.passengers.infants
      }
      if (this.promotion.flightDirection === 'round') {
        this.$store.dispatch('setDates', {
          data: this.promotion.returnDate,
          type: 'arrival'
        })
        searchParameters.arrivalDate = searchStore.flightDates.arrivalDate
      }
      data = Object.entries(searchParameters)
        .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        .join('&')

      this.$store.dispatch('setFlightsTimeOut', false)
      if (this.$store.state.flightsSearchTimeOutFunction !== null) {
        this.$store.dispatch('clearFlightsSearchTimeoutFunction')
      }
      if (this.$store.state.flightsTimeOutFunction !== null) {
        this.$store.dispatch('clearFlightsTimeoutFunction')
      }
      this.$store.dispatch('removeFlightResults')
      this.$router.push({
        name: 'flightResults',
        params: { term: data },
        query: searchParameters
      })
    }
  },
  created () {
    if (this.promotion) this.chosenClass = this.promotion.cabinClasses[0]
  }
}
</script>

<style>
.glass-bg {
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  -moz-backdrop-filter: blur(50px);
  -ms-backdrop-filter: blur(50px);
}
</style>
