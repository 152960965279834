<template>
  <v-app class="app-body">
    <div class="blueDark text-end">
      <div class="container pa-1">
        <a style="text-decoration: none;" href="mailto:info@fare33.com" target="_blank" class="white--text"><v-icon left small color="white" class="mb-1">mdi-email</v-icon>info@fare33.com</a>
      </div>
    </div>

    <navbar />
    <v-main>
      <router-view :key="$route.path"></router-view>
    </v-main>
    <!-- <v-btn @click="sendPaymentDataToAnet">click</v-btn> -->
    <footerBar />
  </v-app>
</template>

<script>
import navbar from './components/navbar.vue'
import footerBar from './components/footer.vue'
import { profile, headersNoAuth, headers, homeTrips, getFlightPromotions } from './links'
// import * as kount from 'https://tst.kaptcha.com/collect/sdk?m=100457'

export default {
  name: 'App',
  components: {
    navbar,
    footerBar
  },
  data () {
    return {
      topWindow: 'hello',
      users: [],
      version: '2.1'
    }
  },
  computed: {
    showNav () {
      if (!this.mode || this.mode === 'fare') return true
      else return (this.$route.name !== 'approve' && this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'verify' && this.$route.name !== 'resetPassword')
    }
  },
  mounted () {
    if (this.$store.state.version !== this.version) {
      this.$store.dispatch('removeAirportsData')
      this.$store.dispatch('removeDatesData')
      this.$store.dispatch('removePassengersData')
      this.$store.dispatch('setPreferredAirline', null)
      this.$store.dispatch('setVersion', this.version)
    }
    // this.$store.dispatch('setUserMails', this.users)
    if (this.$cookies.isKey('userToken')) {
      this.$http.get(profile, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status) this.$store.dispatch('setUser', response.body.data)
        else {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('userToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('token')
        }
      })
    } else {
      this.$store.dispatch('removeUser')
      this.$cookies.remove('userToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('token')
    }
    this.$http.get(homeTrips, { headers: headersNoAuth() }).then(response => {
      this.$store.dispatch('setHomeTrips', response.body.data)
    })
    this.$http.get(getFlightPromotions, { headers: headersNoAuth() }).then(response => {
      console.log(response.body.data)
      this.$store.dispatch('setPromotions', response.body.data)
    })
  },
  created () {
    this.$http.interceptors.push((request, next) => {
      next(response => {
        if (response.status === 401) {
          this.$store.dispatch('removeUser')
          this.$cookies.remove('userToken')
          localStorage.removeItem('userId')
          localStorage.removeItem('token')
          this.$router.push({ name: 'login' })
        }
      })
    })
    // window.addEventListener('beforeunload', (e) => {
    //   e.preventDefault()
    //   this.$store.dispatch('removeGuestData')
    // })
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
  .v-application {
    max-width: 100vw;
  }

  .font-header {
    font-family: 'Playball', cursive;
    font-size: 31px;
  }
</style>
