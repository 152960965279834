<template>
  <div>
    <v-snackbar v-model="snackbar" :color="color" top :timeout="5000">
      {{ text }}
      <v-btn icon @click="snackbar = false"
        ><v-icon color="white">mdi-close</v-icon></v-btn
      >
    </v-snackbar>
    <search />
    <!-- <v-container class="my-15 home-cont">
      <p class="text-center mt-15 mb-0 font-header secondary--text">Take Yourself</p>
      <h2 class="text-center font-weight-medium section-title blueLight--text font-weight-regular text-h3 mb-2">Trips Ideas</h2>
      <featured class="mx-5"></featured>
    </v-container> -->

    <v-container class="my-15 promotions-cont" v-if="promotions.length">
      <h1 class="text-h3 font-weight-bold mb-5">
        Travel and<br />
        Save More
      </h1>
      <carousel :responsive="{ 0:{items:1}, 600:{items:2}, 1275:{items:3}, 1800:{items:4} }" :margin="25" :nav="false">
        <promotion-card v-for="item in promotions" :key="item.id" :promotion="item"/>
      </carousel>
    </v-container>

    <div class="adv mb-15 py-10">
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="10" md="6">
          <h1 class="font-weight-bold white--text text-h2 mb-10">
            JOIN US NOW AND GET 1K POINTS
          </h1>
          <p class="body-1 white--text mb-10">
            Join us and collect a thousand points to get a chance of getting free tickets
            The more you collect the more you get.
          </p>
          <v-btn link to="/register" rounded color="orange" large dark class="py-7 px-10"
            >Register Now
            <v-icon right color="navy-blue">mdi-chevron-right-circle</v-icon></v-btn
          >
          <v-btn link to="/rewards" text color="orange darken-1" large
            >Learn more
            <v-icon right color="white">mdi-chevron-right-circle</v-icon></v-btn
          >
        </v-col>
        <v-col cols="10" md="5">
          <v-img
            class="my-0 rounded-xl"
            style="filter: drop-shadow(0px 12px 10px #0000ff50)"
            src="../static/ticket.jpg"
            alt="Fare33 Mobile App"
          ></v-img>
        </v-col>
      </v-row>
    </div>

    <v-container class="my-15 promotions-cont">
        <h1 class="text-h3 text-center font-weight-bold mb-10">
          What We
          Offer ?
        </h1>

        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" md="3" class="text-center">
            <img src="../static/icons/flight.png" alt="">
            <h4 class="my-2">Affordable Flights</h4>
            <p class="caption">Fly all over the world and pay the cheapest prices.</p>
            <v-btn rounded elevation="0" small color="orange lighten-2 white--text" to="/flights">fly now <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="text-center">
            <img src="../static/icons/stay.png" alt="">
            <h4 class="my-2">Luxurious Stays</h4>
            <p class="caption">Stay at the most luxurious hotels around the globe.</p>
            <v-btn rounded elevation="0" small color="orange lighten-2 white--text" to="/hotels">Try it <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="text-center">
            <img src="../static/icons/cars.png" alt="">
            <h4 class="my-2">Cars Rentals</h4>
            <p class="caption">Choose from a great collection of all purposes cars.</p>
            <v-btn rounded elevation="0" small color="orange lighten-2 white--text" to="/cars">Let's Drive <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="text-center">
            <img src="../static/icons/reward.png" alt="">
            <h4 class="my-2">Great Rewards</h4>
            <p class="caption">Collect points on every booking you complete and joinig our community.</p>
            <v-btn rounded elevation="0" small color="orange lighten-2 white--text" to="/rewards">Know More <v-icon right>mdi-chevron-right</v-icon></v-btn>
          </v-col>
        </v-row>
    </v-container>

    <div class="grey lighten-5 py-10">
      <v-container>
        <h1 class="text-h3 font-weight-bold mb-5">
          Experience<br />
          Fun Travel
        </h1>
        <v-row justify="space-between" align="center">
          <v-col cols="12" md="5">
            <v-subheader class="my-5">With Fare33 you can get the best experience on holiday travel, and we always update the latest and best destinations in the world</v-subheader>
            <v-img src="../static/services-bg.jpg" max-width="500" class="rounded-xl mb-5 mx-auto" style="box-shadow: rgba(197, 255, 255, 0.5) 0px 10px 33px;"></v-img>
          </v-col>

          <v-col cols="12" md="5" class="d-block d-sm-flex d-md-block">
            <div class="d-flex align-center">
              <img src="../static/icons/wallet.png" width="70" alt="">
              <div class="ml-5">
                <h4 class="mb-2">Affordable prices</h4>
                <p class="body-1">We provide some very affordable prices compared to others.</p>
              </div>
            </div>
            <v-divider class="ma-3"></v-divider>
            <div class="d-flex align-center">
              <img src="../static/icons/people.png" width="70" alt="">
              <div class="ml-5">
                <h4 class="mb-2">Unforgettable experience</h4>
                <p class="body-1">We provide a travel experience that will be unforgettable.</p>
              </div>
            </div>
            <v-divider class="ma-3"></v-divider>
            <div class="d-flex align-center">
              <img src="../static/icons/heart.png" width="70" alt="">
              <div class="ml-5">
                <h4 class="mb-2">Very Friendly Service</h4>
                <p class="body-1">We will provide excellent and friendly service for the sake of our customers.</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="mobile-cont mt-15">
      <v-container>
        <v-row class="my-15 pt-15" justify="space-between">
          <v-col cols="12" md="5">
            <h2
              class="font-weight-medium section-title blueLight--text font-weight-regular text-h3 mb-2"
            >
              Get our application
            </h2>
            <p class="text-h6 blueLight--text">
              Download the Fare33 application now and freely book flights, hotels and more
              with the ease of your mobile.
            </p>
            <div
              style="width: fit-content"
            >
              <a
                class="mr-5"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.fare33.fare33"
              >
                <img
                  src="../static/play.png"
                  width="200"
                  height="50"
                  alt="DoWNLOAD ON GOOGLE PLAY"
                />
              </a>
              <a target="_blank" href="https://apps.apple.com/eg/app/fare33/id1596648004">
                <img
                  src="../static/app.png"
                  width="200"
                  height="50"
                  alt="DoWNLOAD ON APP STORE"
                />
              </a>
            </div>
          </v-col>
          <v-col style="transform: skew(-10deg, 10deg)" cols="12" md="6">
            <v-img
              class="mx-auto"
              src="../static/phone.png"
              max-width="55%"
              contain
              alt="Fare33 Mobile App"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import promotionCard from '../components/promotionCard.vue'
import goTo from 'vuetify/es5/services/goto'
import search from '../components/searchScreen.vue'
import carousel from 'vue-owl-carousel'

export default {
  title: ' ',
  model: null,
  name: 'Home',
  components: {
    search,
    promotionCard,
    carousel
  },
  data () {
    return {
      el: 1,
      length: 6,
      snackbar: false,
      color: '',
      text: '',
      overlay: false,
      classes: [
        { text: 'Economy', value: 'Y' },
        { text: 'Premium Economy', value: 'S' },
        { text: 'Business', value: 'C' },
        { text: 'First', value: 'F' }
      ],
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 1400, size: 2 },
          { size: 3 }
        ]
      }
    }
  },
  computed: {
    promotions () {
      return this.$store.state.promotions
    }
  },
  methods: {
    // onScroll () {
    //   if (window.top.scrollY === 600) {
    //     document.getElementById('trips').classList.add('scale-in-center')
    //   }
    // },
    goTo (link) {
      window.open(link, '_blank')
    },
    getCols (size) {
      if (size === 'sm') {
        const cols = this.length === 1 ? 12 : 6
        return cols
      } else {
        let cols
        switch (this.length) {
          case 1:
            cols = 12
            break
          case 2:
            cols = 6
            break
          case 3:
            cols = 4
            break
          case 4:
            cols = 3
            break
          case 6:
            cols = 4
            break
        }
        return cols
      }
    },
    getThisDate (date) {
      const givenDate = new Date(date)
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const day = givenDate.getDate()
      return `${months[givenDate.getMonth()]} ${day}, ${givenDate.getFullYear()}`
    }
  },
  created () {
    window.scrollTo(0, 0)
    window.addEventListener('keydown', function (e) {
      if (e.keyCode === 34) {
        goTo(window.pageYOffset + window.innerHeight + 50)
      } else if (e.keyCode === 33) window.scrollBy(0, -100)
    })
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style>
.section-title {
  position: relative;
  line-height: 80px !important;
}
.mobile-cont {
  background-image: url("../static/bg2.png");
  background-size: contain;
  background-position: right, center;
}
.adv {
  width: 100%;
  background: linear-gradient(182deg, #325aaf, #325aaf70);
}
.promotions-cont {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
</style>
