const endPoint = process.env.NODE_ENV === 'development' ? 'https://dev.atnbookingengine.com/api' : 'https://www.atnbookingengine.com/api'
const endPointV2 = process.env.NODE_ENV === 'development' ? 'https://dev.atnbookingengine.com/v2/api' : 'https://www.atnbookingengine.com/v2/api'

// app log
export const appLog = `${endPoint}/applicationLog`

// app currency
export const currencies = `${endPoint}/getCurrencies`

// device token for notification
export const deviceToken = `${endPoint}/user/device-token`

// trips
export const homeTrips = `${endPoint}/trips-in-home/8`
export const trips = `${endPoint}/trips`
export const bookTrip = `${endPointV2}/trips/request-to-book`
export const allCities = `${endPointV2}/tours/list-city`

// airlines
export const getAirlines = (term) => `${endPointV2}/flights/airlines/autocomplete?term=${term}`
export const getAirports = (term) => `${endPoint}/airport/autocomplete?term=${term}`
export const searchFlights = (term) => `${endPoint}/flights/search?${term}`
export const getFlight = (id, search, relatedFlightId) => {
  if (relatedFlightId) return `${endPoint}/flights?flight_id=${id}&search_id=${search}&related_flight_id=${relatedFlightId}`
  else return `${endPoint}/flights?flight_id=${id}&search_id=${search}`
}
export const checkFlight = `${endPoint}/flightAvailability`
export const getFlightPromotions = `${endPoint}/flights/promotions`
export const createPNR = `${endPoint}/pnr/create`
export const showPNR = (pnr) => `${endPoint}/pnr/${pnr}`
export const bookFlightByMail = `${endPointV2}/flights/send-special-offer-request-mail`

// user data
export const register = `${endPoint}/user/register`
export const profile = `${endPoint}/user/profile`
export const userBookings = `${endPoint}/user/bookings`
export const userUpdate = `${endPoint}/user/update`
export const updateNotification = `${endPoint}/user/updateNotification`
export const login = `${endPoint}/user/login`
export const logout = `${endPoint}/user/logout`
export const collectPoints = `${endPoint}/user/collect-points-history`
export const redeem = `${endPoint}/user/redeem-points-history`
export const cancelFlight = `${endPoint}/user/bookings/cancel-flight`

// auth
export const verify = `${endPoint}/email/verify`
export const resend = `${endPoint}/email/resend`
export const resetPasswordCode = `${endPoint}/reset-password-code`
export const resetPassword = `${endPoint}/reset-password`

// cities
export const getCities = (term) => `${endPointV2}/cities/autocomplete?term=${term}`
export const getCitiesByCode = (code) => `${endPointV2}/cities/autocomplete?code=${code}&term=''`
export const searchHotels = (term) => `${endPointV2}/hotels/search?${term}`
export const getHotel = (sessionId, hotelIndex, hotelCode) => `${endPointV2}/hotels/show?sessionId=${sessionId}&hotelIndex=${hotelIndex}&hotelCode=${hotelCode}`
export const hotelAvailability = (term) => `${endPointV2}/hotels/room/availability?${term}`
export const bookHotel = `${endPointV2}/hotels/book`
export const holdRooms = `${endPointV2}/hotels/hold-booking`

// cars
export const carsSearch = (term) => `${endPoint}/cars/search?${term}`
export const showCar = (term) => `${endPoint}/cars/show?${term}`
export const rentCar = `${endPoint}/cars/book`

// insurance
export const calculateInsurace = `${endPoint}/insurance/price-and-plan`

// SEO endpoints
export const continueToPayment = `${endPoint}/flights/continue-to-payment`

// headers
export function headers (token) {
  return { Authorization: 'Bearer ' + token, Accept: 'application/json', 'Content-type': 'application/json' }
}
export function headersNoAuth () {
  if (window.$cookies.isKey('userToken')) return { Authorization: 'Bearer ' + window.$cookies.get('userToken'), Accept: 'application/json', 'Content-type': 'application/json' }
  else return { Accept: 'application/json', 'Content-type': 'application/json' }
}
