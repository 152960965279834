<template>
  <!-- <v-col :id="type === 'airline' ? 'airlines' : ''" cols="12" :sm="type === 'carPickup' ? 12 : (type === 'carDrop' ? 12 : 6)" :md="type === 'carPickup' ? 6 : (type === 'carDrop' ? 6 : (type === 'airline' ? 3 : 6))" :lg="type === 'carPickup' ? 2 : (type === 'carDrop' ? 2 : (type === 'airline' ? 3 : '6'))" class="py-0 my-0"> -->
    <v-autocomplete
    :id="`autocomplete-input-${type}`"
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :prepend-inner-icon="icon"
    hide-no-data
    outlined
    clearable
    no-filter
    :menu-props="{ auto: false, maxWidth: 'auto', overflowY: false }"
    :allow-overflow="false"
    item-text="Name"
    item-value="Code"
    color="blueDark"
    class="rounded-lg"
    :class="props.class"
    height="52"
    single-line
    return-object
    :hint="type === 'carDrop' ? 'optional' : ''"
    auto-select-first
    :placeholder="props.placeholder"
    :rules="(type === 'carPickup' && $store.state.carInfo.pickupLocation !== null) ? [true] : (type === 'carDrop' ? [true] : (type === 'airline' ? [true] : [v => !!v || 'Item is required']))"
    append-icon=""
    >
    <!-- :color="type === 'airline' ? 'white' : 'secondary'" -->
      <template :id="`list-item-${data.item.code}`" v-slot:item="data">
        <v-list-item-content v-if="type === 'airline'">
          <v-list-item-title>{{data.item.name}}</v-list-item-title>
          <v-list-item-subtitle>{{data.item.code}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>{{data.item.Name}}, {{data.item.City}}</v-list-item-title>
          <v-list-item-subtitle>{{data.item.Country}},{{ data.item.Code}}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:selection="data">
        <span v-if="type === 'airline'">{{data.item.name}},{{data.item.code}}</span>
        <span v-else :id="'ac-selection-' + type" class="ac-selection">{{data.item.Name}},{{data.item.Code}}</span>
      </template>
    </v-autocomplete>
  <!-- </v-col> -->
</template>

<script>
import { getAirports, getAirlines, headersNoAuth } from '../links'

export default {
  props: ['type', 'getSecondDeparture', 'getThirdDeparture'],
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      firstRequest: true
    }
  },
  computed: {
    icon () {
      if (this.type === 'Departure' || this.type === 'Second Departure' || this.type === 'Third Departure') {
        return 'mdi-airplane-takeoff'
      } else if (this.type === 'carDrop' || this.type === 'carPickup') {
        return 'mdi-car'
      } else if (this.type === 'airline') {
        return 'mdi-airport'
      } else {
        return 'mdi-airplane-landing'
      }
    },
    initialValue () {
      let value
      const airports = this.$store.state.airports
      const carInfo = this.$store.state.carInfo
      const preferredAirline = this.$store.state.preferredAirline
      const search = this.$route.query
      // let searchTerm = null
      // if (search) {
      //   searchTerm = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === '' ? value : decodeURIComponent(value) })
      // }
      switch (this.type) {
        case 'Departure':
          !search.origin ? value = `${airports.departureAirports[0].City},${airports.departureAirports[0].Code}` : (search.origin ? value = 'name,' + search.origin : value = '')
          break
        case 'Arrival':
          !search.destination ? value = `${airports.arrivalAirports[0].City},${airports.arrivalAirports[0].Code}` : (search.destination ? value = 'name,' + search.destination : value = '')
          break
        case 'Second Departure':
          airports.departureAirports[1] !== null ? value = `${airports.departureAirports[1].City},${airports.departureAirports[1].Code}` : (airports.arrivalAirports[0] !== null ? value = `${airports.arrivalAirports[0].City},${airports.arrivalAirports[0].Code}` : value = '')
          break
        case 'Second Arrival':
          airports.arrivalAirports[1] !== null ? value = `${airports.arrivalAirports[1].City},${airports.arrivalAirports[1].Code}` : value = ''
          break
        case 'Third Departure':
          airports.departureAirports[2] !== null ? value = `${airports.departureAirports[2].City},${airports.departureAirports[2].Code}` : (airports.arrivalAirports[1] !== null ? value = `${airports.arrivalAirports[1].City},${airports.arrivalAirports[1].Code}` : value = '')
          break
        case 'Third Arrival':
          airports.arrivalAirports[2] !== null ? value = `${airports.arrivalAirports[2].City},${airports.arrivalAirports[2].Code}` : value = ''
          break
        case 'carDrop':
          carInfo.dropLocation !== null ? value = `${carInfo.dropLocation.City},${carInfo.dropLocation.Code}` : value = ''
          break
        case 'carPickup':
          carInfo.pickupLocation !== null ? value = `${carInfo.pickupLocation.City},${carInfo.pickupLocation.Code}` : value = ''
          break
        case 'airline':
          !search.preferredAirline ? (preferredAirline ? value = `${preferredAirline.name},${preferredAirline.code}` : value = '') : (search.preferredAirline ? value = 'name,' + search.preferredAirline : value = '')
          break
        default:
          break
      }
      return value
    },
    props () {
      const value = {}
      switch (this.type) {
        case 'Departure':
          value.placeholder = 'From'
          if (this.$vuetify.breakpoint.mdAndUp) value.class = 'rounded-tr-0 rounded-br-0'
          else value.class = ''
          break
        case 'Arrival':
          value.placeholder = 'To'
          if (this.$vuetify.breakpoint.mdAndUp) value.class = 'rounded-tl-0 rounded-bl-0'
          else value.class = ''
          break
        case 'Second Departure':
          value.placeholder = 'From'
          if (this.$vuetify.breakpoint.mdAndUp) value.class = 'rounded-tr-0 rounded-br-0'
          else value.class = ''
          break
        case 'Second Arrival':
          value.placeholder = 'To'
          if (this.$vuetify.breakpoint.mdAndUp) value.class = 'rounded-tl-0 rounded-bl-0'
          else value.class = ''
          break
        case 'Third Departure':
          value.placeholder = 'From'
          if (this.$vuetify.breakpoint.mdAndUp) value.class = 'rounded-tr-0 rounded-br-0'
          else value.class = ''
          break
        case 'Third Arrival':
          value.placeholder = 'To'
          if (this.$vuetify.breakpoint.mdAndUp) value.class = 'rounded-tl-0 rounded-bl-0'
          else value.class = ''
          break
        case 'carDrop':
          value.placeholder = 'Drop Off Location (optional)'
          if (this.$vuetify.breakpoint.smAndUp) value.class = 'rounded-tl-0 rounded-bl-0'
          else value.class = ''
          break
        case 'carPickup':
          value.placeholder = 'Pick Up Location'
          if (this.$vuetify.breakpoint.smAndUp) value.class = 'rounded-tr-0 rounded-br-0'
          else value.class = ''
          break
        case 'airline':
          value.placeholder = 'Preferred airline'
          value.class = ''
          break
        default:
          break
      }
      return value
    },
    secondDeparture () {
      return this.getSecondDeparture
    },
    thirdDeparture () {
      return this.getThirdDeparture
    }
  },
  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    select (value) {
      switch (this.type) {
        case 'Departure':
          this.$store.dispatch('setAirports', { data: value, type: 'departure1' })
          break
        case 'Arrival':
          this.$store.dispatch('setAirports', { data: value, type: 'arrival1' })
          this.$emit('setSecondDeparture', value)
          break
        case 'Second Departure':
          this.$store.dispatch('setAirports', { data: value, type: 'departure2' })
          break
        case 'Second Arrival':
          this.$store.dispatch('setAirports', { data: value, type: 'arrival2' })
          this.$emit('setThirdDeparture', value)
          break
        case 'Third Departure':
          this.$store.dispatch('setAirports', { data: value, type: 'departure3' })
          break
        case 'Third Arrival':
          this.$store.dispatch('setAirports', { data: value, type: 'arrival3' })
          break
        case 'carPickup':
          this.$store.dispatch('setAirports', { data: value, type: 'carPickup' })
          break
        case 'carDrop':
          this.$store.dispatch('setAirports', { data: value, type: 'carDrop' })
          break
        case 'airline':
          if (!value) value = null
          this.$store.dispatch('setPreferredAirline', value)
          break
        default:
          break
      }
    },
    secondDeparture (val) {
      if (this.type === 'Second Departure' && val && !this.$store.state.airports.departureAirports[1]) {
        this.search = val.Code
      }
    },
    thirdDeparture (val) {
      if (this.type === 'Third Departure' && val && !this.$store.state.airports.departureAirports[2]) {
        this.search = val.Code
      }
    }
  },
  methods: {
    querySelections (v) {
      if (this.type === 'airline') {
        this.loading = true
        this.$http.get(getAirlines(v), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
          // this.$http.post(getAirlines, body, { headers: headersNoAuth() }).then(response => response.json()).then(response => {
          this.items = response
          if (response.length === 1) this.select = response[0]
          else {
            for (let index = 0; index < response.length; index++) {
              if (response[index].code === this.initialValue.split(',')[1]) this.select = response[index]
            }
          }
          this.loading = false
        }, () => {
          this.loading = false
        })
      } else {
        clearTimeout(this._timerId)
        let delay
        v.length === 1 ? delay = 100 : (v.length === 2 ? delay = 50 : delay = 0)
        this._timerId = setTimeout(() => {
          this.loading = true
          this.$http.get(getAirports(v), { headers: headersNoAuth() }).then(response => response.json()).then(response => {
          // this.$http.post(getAirports, body, { headers: headersNoAuth(), timeout: 10000 }).then(response => response.json()).then(response => {
            if (Array.isArray(response)) this.items = response
            else {
              this.$emit('error')
              this.loading = false
              this.items = []
            }
            // if (response.length === 1) this.select = response[0]
            // else {
            if (this.firstRequest) {
              this.loading = false
              for (let index = 0; index < response.length; index++) {
                if (response[index].Code === this.initialValue.split(',')[1]) this.select = response[index]
              }
              // this.select = response[0]
            } else {
              for (let index = 0; index < response.length; index++) {
                if (this.secondDeparture && response[index].Code === this.secondDeparture.Code && this.type === 'Second Departure') this.select = response[index]
                if (this.thirdDeparture && response[index].Code === this.thirdDeparture.Code && this.type === 'Third Departure') this.select = response[index]
              }
            }
            this.firstRequest = false
            // }
          }).then(() => (this.loading = false))
        }, delay)
        // }
      }
    }
  },
  mounted () {
    if (this.initialValue) {
      this.search = this.initialValue.split(',')[1]
    }
  }
}
</script>

<style>
</style>
